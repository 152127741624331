import {
  App,
  AppFeature,
  AppGroup,
  AppView,
  appPermissionAccessLevels,
  appPermissionStates,
} from 'types/apps';
import {
  IconApi,
  IconArchive,
  IconBell,
  IconBellRinging,
  IconBrowser,
  IconBuildingFactory2,
  IconBuildingStore,
  IconBuildingWarehouse,
  IconCalendarEvent,
  IconCar,
  IconCash,
  IconChartDonut2,
  IconChartDots,
  IconChecklist,
  IconClipboardCheck,
  IconCloudComputing,
  IconCpu,
  IconCreditCard,
  IconEdit,
  IconFileCertificate,
  IconFileDollar,
  IconFileInvoice,
  IconFiles,
  IconFileSpreadsheet,
  IconFileUpload,
  IconForms,
  IconHomePlus,
  IconInbox,
  IconInfoCircle,
  IconList,
  IconListCheck,
  IconListDetails,
  IconListSearch,
  IconLockAccess,
  IconMap2,
  IconMessageChatbot,
  IconPackage,
  IconParachute,
  IconPlaneDeparture,
  IconPlug,
  IconReceipt2,
  IconReplace,
  IconReportAnalytics,
  IconReportMoney,
  IconSearch,
  IconSend,
  IconSettings,
  IconShoppingCart,
  IconShoppingCartQuestion,
  IconSquareRoundedPlus,
  IconTag,
  IconTerminal2,
  IconThumbUp,
  IconTimelineEvent,
  IconTruckDelivery,
  IconUser,
  IconUsers,
  IconUsersGroup,
  IconZoomMoney,
} from '@tabler/icons-react';
import { MOBILE_INVENTORY_ROUTES } from 'routes/constants';

export enum ALL_APP_IDS {
  PART_SEARCH = 'PART_SEARCH',
  PART_LIBRARY = 'PART_LIBRARY',
  WATCHLIST = 'WATCHLIST',
  ALTS = 'ALTS',
  COMPLIANCE = 'COMPLIANCE',
  BOMS = 'BOMS',
  QUOTES = 'QUOTES',
  ORDERS = 'ORDERS',
  KITTING = 'KITTING',
  STOCK = 'STOCK',
  MANAGED_INVENTORY = 'MANAGED_INVENTORY',
  WAREHOUSE = 'WAREHOUSE',
  PRODUCTION = 'PRODUCTION',
  USERS = 'USERS',
  ORG_SETTINGS = 'ORG_SETTINGS',
  USER_SETTINGS = 'USER_SETTINGS',
  INTEGRATIONS = 'INTEGRATIONS',
  BILLING = 'BILLING',
  SUPPLIERS = 'SUPPLIERS',
  PROCUREMENT = 'PROCUREMENT',
  REPORTING = 'REPORTING',
  PROPERTIES = 'PROPERTIES',
}

export enum ALL_VIEW_IDS {
  PART_ALL = 'PART_ALL',
  SHIPMENTS = 'SHIPMENTS',
  STOCK_LOCATIONS = 'STOCK_LOCATIONS',
  ADDRESSES = 'ADDRESSES',
  KITTING_PREFERENCES = 'KITTING_PREFERENCES',
  PART_STOCK = 'PART_STOCK',
  STOCK_LOTS = 'STOCK_LOTS',
  ALLOCATIONS = 'ALLOCATIONS',
  HANDLING_TASKS = 'HANDLING_TASKS',
  INVENTORY_PREFERENCES = 'INVENTORY_PREFERENCES',
  ORG_INFO = 'ORG_INFO',
  ORG_PREFERENCES = 'ORG_PREFERENCES',
  ORG_SUBORGS = 'ORG_SUBORGS',
  PROFILE = 'PROFILE',
  SECURITY = 'SECURITY',
  NOTIFICATIONS = 'NOTIFICATIONS',
  BILLING_PLAN = 'BILLING_PLAN',
  BILLING_USAGE = 'BILLING_USAGE',
  BILLING_INVOICES = 'BILLING_INVOICES',
  BILLING_PAYMENTS = 'BILLING_PAYMENTS',
  PROCESS_ORDERS = 'PROCESS_ORDERS',
  ORDERS_TABLE = 'ORDERS_TABLE',
  ORDERS_STATUS = 'ORDERS_STATUS',
  YOUR_ORDERS = 'YOUR_ORDERS',
  ORDER_PREFERENCES = 'ORDER_PREFERENCES',
  PURCHASE_RULES = 'PURCHASE_RULES',
  SUPPLIERS = 'SUPPLIERS',
  PROCUREMENT_DASHBOARD = 'PROCUREMENT_DASHBOARD',
  PURCHASE_REQUESTS = 'PURCHASE_REQUESTS',
  PURCHASE_ORDERS = 'PURCHASE_ORDERS',
  PROCUREMENT_APP_INVOICES = 'PROCUREMENT_APP_INVOICES',
  PROCUREMENT_APP_PACKING_SLIPS = 'PROCUREMENT_APP_PACKING_SLIPS',
  PROCUREMENT_APP_DOC_AI = 'PROCUREMENT_APP_DOC_AI',
  PROCUREMENT_PREFERENCES = 'PROCUREMENT_PREFERENCES',
  REPORTING_APP_SQL_QUERIES = 'REPORTING_APP_SQL_QUERIES',
  INTEGRATIONS_MARKETPLACE = 'INTEGRATIONS_MARKETPLACE',
  INTEGRATIONS_DESIGNER = 'INTEGRATIONS_DESIGNER',
  INTEGRATIONS_LEGACY = 'INTEGRATIONS_LEGACY',
  TEAMS_TABLE = 'TEAMS_TABLE',
  TEAMS_PREFERENCES = 'TEAMS_PREFERENCES',
  CUSTOM_PROPERTIES = 'CUSTOM_PROPERTIES',
}

export enum INTEGRATION_ENTITY_IDS {
  GOOGLE_SHEETS = 'GOOGLE_SHEETS',
  ALTIUM_365 = 'ALTIUM_365',
  FILE = 'FILE',
}

export enum INTEGRATION_FLOW_IDS {
  BOMS = 'BOMS',
  ORDERS = 'ORDERS',
  PART_STOCK = 'PART_STOCK',
  STOCK_LOTS = 'STOCK_LOTS',
  CUSTOM_PARTS = 'CUSTOM_PARTS',
}

export enum ALL_FEATURE_IDS {
  CUSTOM_PARTS = 'CUSTOM_PARTS',
  CUSTOM_PNS = 'CUSTOM_PNS',
  BOMS_GOOGLE_SHEETS = 'BOMS_GOOGLE_SHEETS',
  BOMS_ALTIUM_365 = 'BOMS_ALTIUM_365',
  ORDERS_GOOGLE_SHEETS = 'ORDERS_GOOGLE_SHEETS',
  PART_STOCK_GOOGLE_SHEETS = 'PART_STOCK_GOOGLE_SHEETS',
  STOCK_LOTS_GOOGLE_SHEETS = 'STOCK_LOTS_GOOGLE_SHEETS',
  STOCK_LOTS_FILE = 'STOCK_LOTS_FILE',
  CUSTOM_PARTS_FILE = 'CUSTOM_PARTS_FILE',
  PURCHASING_RELEASE_DATES = 'PURCHASING_RELEASE_DATES',
  APP_LEVEL_PERMISSIONS = 'APP_LEVEL_PERMISSIONS',
  AECQ = 'AECQ',
  GENERATE_PO = 'GENERATE_PO',
  MANAGED_ORDERS = 'MANAGED_ORDERS',
  CUSTOMER_ORDER_API = 'CUSTOMER_ORDER_API',
  DROP_SHIPPING = 'DROP_SHIPPING',
  BULK_CREATE_EXPECTED = 'BULK_CREATE_EXPECTED',
  SUB_ORGS = 'SUB_ORGS',
  PROCESSING_ORG = 'PROCESSING_ORG',
  PROCESSING_ORG_EMAIL = 'PROCESSING_ORG_EMAIL',
  PROCESSING_ORG_SCAN = 'PROCESSING_ORG_SCAN',
  ORDER_APPROVALS = 'ORDER_APPROVALS',
  CUSTOM_SHIPPING_ACCOUNTS = 'CUSTOM_SHIPPING_ACCOUNTS',
  PROCUREMENT_INVOICES = 'PROCUREMENT_INVOICES',
  PROCUREMENT_PACKING_SLIPS = 'PROCUREMENT_PACKING_SLIPS',
  NO_PART_PURCHASE_LINES = 'NO_PART_PURCHASE_LINES',
  COFACTR_PAY = 'COFACTR_PAY',
  SUPPLIER_PRICING_API = 'SUPPLIER_PRICING_API',
  SUPPLIER_ORDER_API = 'SUPPLIER_ORDER_API',
  SUPPLIER_ONLINE_CHECKOUT = 'SUPPLIER_ONLINE_CHECKOUT',
  SQL_QUERIES = 'SQL_QUERIES',
  INTEGRATIONS_MARKETPLACE = 'INTEGRATIONS_MARKETPLACE',
  INTEGRATIONS_DESIGNER = 'INTEGRATIONS_DESIGNER',
  INTEGRATIONS_LEGACY = 'INTEGRATIONS_LEGACY',
  FILE_ATTACHMENTS = 'FILE_ATTACHMENTS',
}

export enum ALL_APP_GROUP_IDS {
  PARTS = 'PARTS',
  PROCUREMENT = 'PROCUREMENT',
  INVENTORY = 'INVENTORY',
}

export const ALL_APP_GROUPS: { [key in ALL_APP_GROUP_IDS]: AppGroup } = {
  [ALL_APP_GROUP_IDS.PARTS]: {
    id: ALL_APP_GROUP_IDS.PARTS,
    name: 'Parts',
    icon: IconCpu,
  },
  [ALL_APP_GROUP_IDS.PROCUREMENT]: {
    id: ALL_APP_GROUP_IDS.PROCUREMENT,
    name: 'Procurement',
    icon: IconReportMoney,
  },
  [ALL_APP_GROUP_IDS.INVENTORY]: {
    id: ALL_APP_GROUP_IDS.INVENTORY,
    name: 'Inventory',
    icon: IconPackage,
  },
};

export const ALL_FEATURES: { [key in ALL_FEATURE_IDS]: AppFeature } = {
  [ALL_FEATURE_IDS.CUSTOM_PARTS]: {
    id: ALL_FEATURE_IDS.CUSTOM_PARTS,
    name: 'Custom Parts',
    icon: IconSquareRoundedPlus,
    globalComingSoon: false,
    globalBeta: false,
  },
  [ALL_FEATURE_IDS.CUSTOM_PNS]: {
    id: ALL_FEATURE_IDS.CUSTOM_PNS,
    name: 'Custom Part Number',
    icon: IconEdit,
    globalComingSoon: false,
    globalBeta: false,
  },
  [ALL_FEATURE_IDS.AECQ]: {
    id: ALL_FEATURE_IDS.AECQ,
    name: 'AEC-Q Compliance',
    icon: IconCar,
    globalComingSoon: false,
    globalBeta: false,
  },
  [ALL_FEATURE_IDS.DROP_SHIPPING]: {
    id: ALL_FEATURE_IDS.DROP_SHIPPING,
    name: 'Drop Shipping',
    icon: IconParachute,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.BULK_CREATE_EXPECTED]: {
    id: ALL_FEATURE_IDS.BULK_CREATE_EXPECTED,
    name: 'Bulk Create Expected',
    icon: IconListCheck,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: true,
  },
  [ALL_FEATURE_IDS.APP_LEVEL_PERMISSIONS]: {
    id: ALL_FEATURE_IDS.APP_LEVEL_PERMISSIONS,
    name: 'App Level Permissions',
    icon: IconLockAccess,
    globalComingSoon: false,
    globalBeta: true,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.BOMS_GOOGLE_SHEETS]: {
    id: ALL_FEATURE_IDS.BOMS_GOOGLE_SHEETS,
    name: 'BOM Google Sheets',
    icon: IconPlug,
    globalComingSoon: false,
    globalBeta: false,
  },
  [ALL_FEATURE_IDS.BOMS_ALTIUM_365]: {
    id: ALL_FEATURE_IDS.BOMS_ALTIUM_365,
    name: 'BOM Altium 365',
    icon: IconPlug,
    globalComingSoon: false,
    globalBeta: false,
  },
  [ALL_FEATURE_IDS.ORDERS_GOOGLE_SHEETS]: {
    id: ALL_FEATURE_IDS.ORDERS_GOOGLE_SHEETS,
    name: 'Orders Google Sheets',
    icon: IconPlug,
    globalComingSoon: false,
    globalBeta: false,
  },
  [ALL_FEATURE_IDS.PART_STOCK_GOOGLE_SHEETS]: {
    id: ALL_FEATURE_IDS.PART_STOCK_GOOGLE_SHEETS,
    name: 'Part Stock Google Sheets',
    icon: IconPlug,
    globalComingSoon: false,
    globalBeta: false,
  },
  [ALL_FEATURE_IDS.STOCK_LOTS_GOOGLE_SHEETS]: {
    id: ALL_FEATURE_IDS.STOCK_LOTS_GOOGLE_SHEETS,
    name: 'Stock Lots Google Sheets',
    icon: IconPlug,
    globalComingSoon: false,
    globalBeta: false,
  },
  [ALL_FEATURE_IDS.CUSTOM_PARTS_FILE]: {
    id: ALL_FEATURE_IDS.CUSTOM_PARTS_FILE,
    name: 'Custom Parts File',
    icon: IconPlug,
    globalComingSoon: false,
    globalBeta: false,
  },
  [ALL_FEATURE_IDS.STOCK_LOTS_FILE]: {
    id: ALL_FEATURE_IDS.STOCK_LOTS_FILE,
    name: 'Stock Lots File',
    icon: IconPlug,
    globalComingSoon: false,
    globalBeta: false,
  },
  [ALL_FEATURE_IDS.GENERATE_PO]: {
    id: ALL_FEATURE_IDS.GENERATE_PO,
    name: 'Order via PO',
    icon: IconFileInvoice,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.MANAGED_ORDERS]: {
    id: ALL_FEATURE_IDS.MANAGED_ORDERS,
    name: 'Order via Managed Orders',
    icon: IconMessageChatbot,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.CUSTOMER_ORDER_API]: {
    id: ALL_FEATURE_IDS.CUSTOMER_ORDER_API,
    name: 'Order via Customer API',
    icon: IconApi,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.PURCHASING_RELEASE_DATES]: {
    id: ALL_FEATURE_IDS.PURCHASING_RELEASE_DATES,
    name: 'Purchasing Release Dates',
    icon: IconCalendarEvent,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.SUB_ORGS]: {
    id: ALL_FEATURE_IDS.SUB_ORGS,
    name: 'Sub-Organizations',
    icon: IconHomePlus,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.PROCESSING_ORG]: {
    id: ALL_FEATURE_IDS.PROCESSING_ORG,
    name: 'Order Processing Org',
    icon: IconFileUpload,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.PROCESSING_ORG_EMAIL]: {
    id: ALL_FEATURE_IDS.PROCESSING_ORG_EMAIL,
    name: 'Processing Org - Email Inbox',
    icon: IconInbox,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.PROCESSING_ORG_SCAN]: {
    id: ALL_FEATURE_IDS.PROCESSING_ORG_SCAN,
    name: 'Processing Org - Scanning',
    icon: IconInbox,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.ORDER_APPROVALS]: {
    id: ALL_FEATURE_IDS.ORDER_APPROVALS,
    name: 'Order Approvals',
    icon: IconThumbUp,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.CUSTOM_SHIPPING_ACCOUNTS]: {
    id: ALL_FEATURE_IDS.CUSTOM_SHIPPING_ACCOUNTS,
    name: 'Custom Shipping Accounts',
    icon: IconPlaneDeparture,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.PROCUREMENT_INVOICES]: {
    id: ALL_FEATURE_IDS.PROCUREMENT_INVOICES,
    name: 'Procurement App Invoices',
    icon: IconFileInvoice,
    globalComingSoon: true,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.PROCUREMENT_PACKING_SLIPS]: {
    id: ALL_FEATURE_IDS.PROCUREMENT_PACKING_SLIPS,
    name: 'Procurement App Packing Slips',
    icon: IconClipboardCheck,
    globalComingSoon: true,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.NO_PART_PURCHASE_LINES]: {
    id: ALL_FEATURE_IDS.NO_PART_PURCHASE_LINES,
    name: 'Non-Part Purchase Order Lines',
    icon: IconList,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.COFACTR_PAY]: {
    id: ALL_FEATURE_IDS.COFACTR_PAY,
    name: 'Cofactr Pay',
    icon: IconReceipt2,
    globalComingSoon: true,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.SUPPLIER_PRICING_API]: {
    id: ALL_FEATURE_IDS.SUPPLIER_PRICING_API,
    name: 'Supplier Pricing API',
    icon: IconApi,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.SUPPLIER_ORDER_API]: {
    id: ALL_FEATURE_IDS.SUPPLIER_ORDER_API,
    name: 'Supplier Order API',
    icon: IconApi,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.SUPPLIER_ONLINE_CHECKOUT]: {
    id: ALL_FEATURE_IDS.SUPPLIER_ONLINE_CHECKOUT,
    name: 'Supplier Online Checkout',
    icon: IconBrowser,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.SQL_QUERIES]: {
    id: ALL_FEATURE_IDS.SQL_QUERIES,
    name: 'SQL Explorer',
    icon: IconTerminal2,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.INTEGRATIONS_MARKETPLACE]: {
    id: ALL_FEATURE_IDS.INTEGRATIONS_MARKETPLACE,
    name: 'Available Integrations',
    icon: IconPlug,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.INTEGRATIONS_DESIGNER]: {
    id: ALL_FEATURE_IDS.INTEGRATIONS_DESIGNER,
    name: 'Workflow Designer',
    icon: IconCloudComputing,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.INTEGRATIONS_LEGACY]: {
    id: ALL_FEATURE_IDS.INTEGRATIONS_LEGACY,
    name: 'Legacy Integrations',
    icon: IconArchive,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
  [ALL_FEATURE_IDS.FILE_ATTACHMENTS]: {
    id: ALL_FEATURE_IDS.FILE_ATTACHMENTS,
    name: 'File Attachments',
    icon: IconFiles,
    globalComingSoon: false,
    globalBeta: false,
    inheritParentPermissions: false,
  },
};

export const ALL_VIEWS: { [key in ALL_VIEW_IDS]: AppView } = {
  [ALL_VIEW_IDS.PART_ALL]: {
    id: ALL_VIEW_IDS.PART_ALL,
    name: 'All Parts',
    icon: IconListSearch,
    path: '/part-library/all-parts',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.YOUR_ORDERS]: {
    id: ALL_VIEW_IDS.YOUR_ORDERS,
    name: 'Carts',
    icon: IconShoppingCart,
    path: '/orders/snapshots',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.ORDERS_STATUS]: {
    id: ALL_VIEW_IDS.ORDERS_STATUS,
    name: 'Supplier Order Status',
    icon: IconTimelineEvent,
    path: '/orders/status',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.ORDERS_TABLE]: {
    id: ALL_VIEW_IDS.ORDERS_TABLE,
    name: 'All Supplier Orders',
    icon: IconTruckDelivery,
    path: '/orders/table',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.PROCESS_ORDERS]: {
    id: ALL_VIEW_IDS.PROCESS_ORDERS,
    name: 'Process Open Orders',
    icon: IconFileUpload,
    path: '/orders/process',
    includeInSidebar: true,
    inheritedFeatureEntitlement: ALL_FEATURE_IDS.PROCESSING_ORG,
  },
  [ALL_VIEW_IDS.ORDER_PREFERENCES]: {
    id: ALL_VIEW_IDS.ORDER_PREFERENCES,
    name: 'Order Preferences',
    icon: IconSettings,
    path: '/orders/preferences',
    includeInSidebar: false,
    inheritParentPermissions: false,
    inheritedFeatureEntitlement: ALL_FEATURE_IDS.ORDER_APPROVALS,
  },
  [ALL_VIEW_IDS.SHIPMENTS]: {
    id: ALL_VIEW_IDS.SHIPMENTS,
    name: 'Shipments',
    icon: IconPlaneDeparture,
    path: '/kitting/shipping',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.ADDRESSES]: {
    id: ALL_VIEW_IDS.ADDRESSES,
    name: 'Addresses',
    icon: IconMap2,
    path: '/kitting/addresses',
    includeInSidebar: true,
    inheritParentPermissions: true,
    deprecatedPath: '/kitting/stock-locations',
  },
  [ALL_VIEW_IDS.KITTING_PREFERENCES]: {
    id: ALL_VIEW_IDS.KITTING_PREFERENCES,
    name: 'Shipping Preferences',
    icon: IconSettings,
    path: '/kitting/preferences',
    includeInSidebar: false,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.PART_STOCK]: {
    id: ALL_VIEW_IDS.PART_STOCK,
    name: 'Part Stock',
    icon: IconCpu,
    path: '/stock/part-stock',
    includeInSidebar: true,
    excludeInBlended: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.STOCK_LOTS]: {
    id: ALL_VIEW_IDS.STOCK_LOTS,
    name: 'Stock Lots',
    icon: IconPackage,
    path: '/stock/stock-lots',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.ALLOCATIONS]: {
    id: ALL_VIEW_IDS.ALLOCATIONS,
    name: 'Allocations',
    icon: IconChartDonut2,
    path: '/stock/allocations',
    includeInSidebar: true,
    excludeInBlended: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.HANDLING_TASKS]: {
    id: ALL_VIEW_IDS.HANDLING_TASKS,
    name: 'Handling Tasks',
    icon: IconListCheck,
    path: '/managed-inventory/handling-tasks',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.STOCK_LOCATIONS]: {
    id: ALL_VIEW_IDS.STOCK_LOCATIONS,
    name: 'Stock Locations',
    icon: IconBuildingWarehouse,
    path: '/managed-inventory/stock-locations',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.INVENTORY_PREFERENCES]: {
    id: ALL_VIEW_IDS.INVENTORY_PREFERENCES,
    name: 'Inventory Preferences',
    icon: IconSettings,
    path: '/managed-inventory/preferences',
    includeInSidebar: false,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.SUPPLIERS]: {
    id: ALL_VIEW_IDS.SUPPLIERS,
    name: 'Suppliers',
    icon: IconBuildingFactory2,
    path: '/suppliers/suppliers',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.PURCHASE_RULES]: {
    id: ALL_VIEW_IDS.PURCHASE_RULES,
    name: 'Purchase Rules',
    icon: IconZoomMoney,
    path: '/suppliers/purchase-rules',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.BILLING_USAGE]: {
    id: ALL_VIEW_IDS.BILLING_USAGE,
    name: 'Usage',
    icon: IconChartDots,
    path: '/billing/usage',
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.BILLING_INVOICES]: {
    id: ALL_VIEW_IDS.BILLING_INVOICES,
    name: 'Invoices',
    icon: IconFileInvoice,
    path: '/billing/invoices',
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.BILLING_PLAN]: {
    id: ALL_VIEW_IDS.BILLING_PLAN,
    name: 'Subscription',
    icon: IconChecklist,
    path: '/billing/plan',
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.BILLING_PAYMENTS]: {
    id: ALL_VIEW_IDS.BILLING_PAYMENTS,
    name: 'Payments',
    icon: IconCreditCard,
    path: '/billing/payments',
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.ORG_INFO]: {
    id: ALL_VIEW_IDS.ORG_INFO,
    name: 'Info',
    icon: IconInfoCircle,
    path: '/org/info',
    includeInSidebar: false,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.ORG_PREFERENCES]: {
    id: ALL_VIEW_IDS.ORG_PREFERENCES,
    name: 'Preferences',
    icon: IconSettings,
    path: '/org/preferences',
    includeInSidebar: false,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.ORG_SUBORGS]: {
    id: ALL_VIEW_IDS.ORG_SUBORGS,
    name: 'Sub-Organizations',
    icon: IconHomePlus,
    path: '/org/sub-orgs',
    includeInSidebar: false,
    inheritedFeatureEntitlement: ALL_FEATURE_IDS.SUB_ORGS,
  },
  [ALL_VIEW_IDS.PROFILE]: {
    id: ALL_VIEW_IDS.PROFILE,
    name: 'Profile',
    icon: IconUser,
    path: '/user-settings/profile',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.SECURITY]: {
    id: ALL_VIEW_IDS.SECURITY,
    name: 'Security',
    icon: IconLockAccess,
    path: '/user-settings/security',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.NOTIFICATIONS]: {
    id: ALL_VIEW_IDS.NOTIFICATIONS,
    name: 'Notifications',
    icon: IconBell,
    path: '/user-settings/notifications',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.PROCUREMENT_DASHBOARD]: {
    id: ALL_VIEW_IDS.PROCUREMENT_DASHBOARD,
    name: 'Open Orders',
    icon: IconTimelineEvent,
    path: '/procurement/open-orders',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.PURCHASE_REQUESTS]: {
    id: ALL_VIEW_IDS.PURCHASE_REQUESTS,
    name: 'Purchase Requests',
    icon: IconShoppingCartQuestion,
    path: '/procurement/requests',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.PURCHASE_ORDERS]: {
    id: ALL_VIEW_IDS.PURCHASE_ORDERS,
    name: 'Purchase Orders',
    icon: IconFileSpreadsheet,
    path: '/procurement/purchase-orders',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.PROCUREMENT_APP_INVOICES]: {
    id: ALL_VIEW_IDS.PROCUREMENT_APP_INVOICES,
    name: 'Invoices',
    icon: IconFileInvoice,
    path: '/procurement/invoices',
    includeInSidebar: true,
    inheritParentPermissions: false,
    inheritedFeatureEntitlement: ALL_FEATURE_IDS.PROCUREMENT_INVOICES,
  },
  [ALL_VIEW_IDS.PROCUREMENT_APP_PACKING_SLIPS]: {
    id: ALL_VIEW_IDS.PROCUREMENT_APP_PACKING_SLIPS,
    name: 'Packing Slips',
    icon: IconClipboardCheck,
    path: '/procurement/packing-slips',
    includeInSidebar: true,
    inheritParentPermissions: false,
    inheritedFeatureEntitlement: ALL_FEATURE_IDS.PROCUREMENT_PACKING_SLIPS,
  },
  [ALL_VIEW_IDS.PROCUREMENT_APP_DOC_AI]: {
    id: ALL_VIEW_IDS.PROCUREMENT_APP_DOC_AI,
    name: 'DocAI Inbox',
    icon: IconInbox,
    path: '/procurement/inbox',
    includeInSidebar: true,
    inheritedFeatureEntitlement: ALL_FEATURE_IDS.PROCESSING_ORG_EMAIL,
  },
  [ALL_VIEW_IDS.PROCUREMENT_PREFERENCES]: {
    id: ALL_VIEW_IDS.PROCUREMENT_PREFERENCES,
    name: 'Procurement Preferences',
    icon: IconSettings,
    path: '/procurement/preferences',
    includeInSidebar: false,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.REPORTING_APP_SQL_QUERIES]: {
    id: ALL_VIEW_IDS.REPORTING_APP_SQL_QUERIES,
    name: 'SQL Explorer',
    icon: IconTerminal2,
    path: '/reporting/sql',
    includeInSidebar: true,
    inheritParentPermissions: false,
    inheritedFeatureEntitlement: ALL_FEATURE_IDS.SQL_QUERIES,
  },
  [ALL_VIEW_IDS.INTEGRATIONS_LEGACY]: {
    id: ALL_VIEW_IDS.INTEGRATIONS_LEGACY,
    name: 'Legacy',
    icon: IconArchive,
    path: '/integrations/legacy',
    inheritParentPermissions: false,
    inheritedFeatureEntitlement: ALL_FEATURE_IDS.INTEGRATIONS_LEGACY,
  },
  [ALL_VIEW_IDS.INTEGRATIONS_MARKETPLACE]: {
    id: ALL_VIEW_IDS.INTEGRATIONS_MARKETPLACE,
    name: 'Available Integrations',
    icon: IconBuildingStore,
    path: '/integrations/marketplace',
    inheritParentPermissions: false,
    inheritedFeatureEntitlement: ALL_FEATURE_IDS.INTEGRATIONS_MARKETPLACE,
  },
  [ALL_VIEW_IDS.INTEGRATIONS_DESIGNER]: {
    id: ALL_VIEW_IDS.INTEGRATIONS_DESIGNER,
    name: 'Workflow Designer',
    icon: IconEdit,
    path: '/integrations/designer',
    inheritParentPermissions: false,
    inheritedFeatureEntitlement: ALL_FEATURE_IDS.INTEGRATIONS_DESIGNER,
  },
  [ALL_VIEW_IDS.TEAMS_TABLE]: {
    id: ALL_VIEW_IDS.TEAMS_TABLE,
    name: 'Teams',
    icon: IconUsersGroup,
    path: '/properties/teams',
    includeInSidebar: false,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.TEAMS_PREFERENCES]: {
    id: ALL_VIEW_IDS.TEAMS_PREFERENCES,
    name: 'Team Preferences',
    icon: IconSettings,
    path: '/properties/team-preferences',
    includeInSidebar: false,
    inheritParentPermissions: true,
  },
  [ALL_VIEW_IDS.CUSTOM_PROPERTIES]: {
    id: ALL_VIEW_IDS.CUSTOM_PROPERTIES,
    name: 'Custom Properties',
    icon: IconForms,
    path: '/properties/custom-properties',
    includeInSidebar: true,
    inheritParentPermissions: true,
  },
};

export const ALL_APPS: { [key in ALL_APP_IDS]: App } = {
  [ALL_APP_IDS.PART_SEARCH]: {
    id: ALL_APP_IDS.PART_SEARCH,
    name: 'Part Search',
    icon: IconSearch,
    path: '/parts-search',
    appGroup: ALL_APP_GROUPS.PARTS,
    includeInSidebar: true,
    globalEnabled: false,
    requiredPermissionLevel: appPermissionAccessLevels.read,
  },
  [ALL_APP_IDS.PART_LIBRARY]: {
    id: ALL_APP_IDS.PART_LIBRARY,
    name: 'Part Library',
    icon: IconCpu,
    path: '/part-library',
    appGroup: ALL_APP_GROUPS.PARTS,
    includeInSidebar: true,
    globalEnabled: false,
    requiredPermissionLevel: appPermissionAccessLevels.read,
    views: {
      [ALL_VIEW_IDS.PART_ALL]: ALL_VIEWS[ALL_VIEW_IDS.PART_ALL],
    },
    features: {
      [ALL_FEATURE_IDS.CUSTOM_PARTS]:
        ALL_FEATURES[ALL_FEATURE_IDS.CUSTOM_PARTS],
      [ALL_FEATURE_IDS.CUSTOM_PNS]: ALL_FEATURES[ALL_FEATURE_IDS.CUSTOM_PNS],
    },
  },
  [ALL_APP_IDS.WATCHLIST]: {
    id: ALL_APP_IDS.WATCHLIST,
    name: 'Watchlist',
    icon: IconBellRinging,
    path: '/watchlist',
    appGroup: ALL_APP_GROUPS.PARTS,
    includeInSidebar: true,
    globalEnabled: false,
  },
  [ALL_APP_IDS.ALTS]: {
    id: ALL_APP_IDS.ALTS,
    name: 'Alternates',
    icon: IconReplace,
    path: null,
    appGroup: ALL_APP_GROUPS.PARTS,
    includeInSidebar: false,
    globalEnabled: false,
    requiredPermissionLevel: appPermissionAccessLevels.read,
  },
  [ALL_APP_IDS.COMPLIANCE]: {
    id: ALL_APP_IDS.COMPLIANCE,
    name: 'Compliance & Risk',
    icon: IconFileCertificate,
    path: null,
    appGroup: ALL_APP_GROUPS.PARTS,
    includeInSidebar: false,
    globalEnabled: false,
    features: {
      [ALL_FEATURE_IDS.AECQ]: ALL_FEATURES[ALL_FEATURE_IDS.AECQ],
    },
  },
  [ALL_APP_IDS.BOMS]: {
    id: ALL_APP_IDS.BOMS,
    name: 'BOMs',
    icon: IconListDetails,
    path: '/boms',
    appGroup: null,
    includeInSidebar: true,
    includeInBlended: true,
    globalEnabled: false,
    isRequiredFor: [ALL_APP_IDS.PRODUCTION],
  },
  [ALL_APP_IDS.QUOTES]: {
    id: ALL_APP_IDS.QUOTES,
    name: 'Quoting',
    icon: IconSend,
    path: '/quotes',
    appGroup: ALL_APP_GROUPS.PROCUREMENT,
    includeInSidebar: true,
    includeInBlended: true,
    globalEnabled: false,
    isRequiredFor: [ALL_APP_IDS.ORDERS],
  },
  [ALL_APP_IDS.PROCUREMENT]: {
    id: ALL_APP_IDS.PROCUREMENT,
    name: 'Procurement',
    icon: IconFileDollar,
    path: '/procurement',
    appGroup: ALL_APP_GROUPS.PROCUREMENT,
    includeInSidebar: true,
    includeInBlended: true,
    globalEnabled: false,
    globalBeta: false,
    views: {
      [ALL_VIEW_IDS.PROCUREMENT_DASHBOARD]:
        ALL_VIEWS[ALL_VIEW_IDS.PROCUREMENT_DASHBOARD],
      [ALL_VIEW_IDS.PURCHASE_REQUESTS]:
        ALL_VIEWS[ALL_VIEW_IDS.PURCHASE_REQUESTS],
      [ALL_VIEW_IDS.PURCHASE_ORDERS]: ALL_VIEWS[ALL_VIEW_IDS.PURCHASE_ORDERS],
      [ALL_VIEW_IDS.PROCUREMENT_APP_INVOICES]:
        ALL_VIEWS[ALL_VIEW_IDS.PROCUREMENT_APP_INVOICES],
      [ALL_VIEW_IDS.PROCUREMENT_APP_PACKING_SLIPS]:
        ALL_VIEWS[ALL_VIEW_IDS.PROCUREMENT_APP_PACKING_SLIPS],
      [ALL_VIEW_IDS.PROCUREMENT_APP_DOC_AI]:
        ALL_VIEWS[ALL_VIEW_IDS.PROCUREMENT_APP_DOC_AI],
      [ALL_VIEW_IDS.PROCUREMENT_PREFERENCES]:
        ALL_VIEWS[ALL_VIEW_IDS.PROCUREMENT_PREFERENCES],
    },
    features: {
      [ALL_FEATURE_IDS.PROCUREMENT_INVOICES]:
        ALL_FEATURES[ALL_FEATURE_IDS.PROCUREMENT_INVOICES],
      [ALL_FEATURE_IDS.PROCUREMENT_PACKING_SLIPS]:
        ALL_FEATURES[ALL_FEATURE_IDS.PROCUREMENT_PACKING_SLIPS],
      [ALL_FEATURE_IDS.NO_PART_PURCHASE_LINES]:
        ALL_FEATURES[ALL_FEATURE_IDS.NO_PART_PURCHASE_LINES],
      [ALL_FEATURE_IDS.PROCESSING_ORG_EMAIL]:
        ALL_FEATURES[ALL_FEATURE_IDS.PROCESSING_ORG_EMAIL],
    },
  },
  [ALL_APP_IDS.ORDERS]: {
    id: ALL_APP_IDS.ORDERS,
    name: 'Orders',
    icon: IconTruckDelivery,
    path: '/orders',
    appGroup: ALL_APP_GROUPS.PROCUREMENT,
    includeInSidebar: true,
    includeInBlended: true,
    globalEnabled: false,
    isRequiredFor: [ALL_APP_IDS.BILLING],
    views: {
      [ALL_VIEW_IDS.YOUR_ORDERS]: ALL_VIEWS[ALL_VIEW_IDS.YOUR_ORDERS],
      [ALL_VIEW_IDS.ORDERS_STATUS]: ALL_VIEWS[ALL_VIEW_IDS.ORDERS_STATUS],
      [ALL_VIEW_IDS.ORDERS_TABLE]: ALL_VIEWS[ALL_VIEW_IDS.ORDERS_TABLE],
      [ALL_VIEW_IDS.PROCESS_ORDERS]: ALL_VIEWS[ALL_VIEW_IDS.PROCESS_ORDERS],
      [ALL_VIEW_IDS.ORDER_PREFERENCES]:
        ALL_VIEWS[ALL_VIEW_IDS.ORDER_PREFERENCES],
    },
    features: {
      [ALL_FEATURE_IDS.DROP_SHIPPING]:
        ALL_FEATURES[ALL_FEATURE_IDS.DROP_SHIPPING],
      [ALL_FEATURE_IDS.ORDER_APPROVALS]:
        ALL_FEATURES[ALL_FEATURE_IDS.ORDER_APPROVALS],
      [ALL_FEATURE_IDS.PURCHASING_RELEASE_DATES]:
        ALL_FEATURES[ALL_FEATURE_IDS.PURCHASING_RELEASE_DATES],
    },
    shortcuts: ['BILLING.views.BILLING_INVOICES'],
  },
  [ALL_APP_IDS.KITTING]: {
    id: ALL_APP_IDS.KITTING,
    name: 'Kitting & Shipping',
    icon: IconPlaneDeparture,
    path: '/kitting',
    appGroup: ALL_APP_GROUPS.INVENTORY,
    includeInSidebar: true,
    globalEnabled: false,
    isRequiredFor: [ALL_APP_IDS.MANAGED_INVENTORY, ALL_APP_IDS.WAREHOUSE],
    views: {
      [ALL_VIEW_IDS.SHIPMENTS]: ALL_VIEWS[ALL_VIEW_IDS.SHIPMENTS],
      [ALL_VIEW_IDS.ADDRESSES]: ALL_VIEWS[ALL_VIEW_IDS.ADDRESSES],
      [ALL_VIEW_IDS.KITTING_PREFERENCES]:
        ALL_VIEWS[ALL_VIEW_IDS.KITTING_PREFERENCES],
    },
    features: {
      [ALL_FEATURE_IDS.CUSTOM_SHIPPING_ACCOUNTS]:
        ALL_FEATURES[ALL_FEATURE_IDS.CUSTOM_SHIPPING_ACCOUNTS],
    },
    shortcuts: [
      'MANAGED_INVENTORY.views.INVENTORY_PREFERENCES',
      'MANAGED_INVENTORY.views.STOCK_LOCATIONS',
    ],
  },
  [ALL_APP_IDS.STOCK]: {
    id: ALL_APP_IDS.STOCK,
    name: 'Stock',
    icon: IconPackage,
    path: '/stock',
    appGroup: ALL_APP_GROUPS.INVENTORY,
    includeInSidebar: true,
    includeInBlended: true,
    globalEnabled: false,
    isRequiredFor: [
      ALL_APP_IDS.KITTING,
      ALL_APP_IDS.MANAGED_INVENTORY,
      ALL_APP_IDS.WAREHOUSE,
    ],
    views: {
      [ALL_VIEW_IDS.PART_STOCK]: ALL_VIEWS[ALL_VIEW_IDS.PART_STOCK],
      [ALL_VIEW_IDS.STOCK_LOTS]: ALL_VIEWS[ALL_VIEW_IDS.STOCK_LOTS],
      [ALL_VIEW_IDS.ALLOCATIONS]: ALL_VIEWS[ALL_VIEW_IDS.ALLOCATIONS],
    },
    shortcuts: [
      'MANAGED_INVENTORY.views.INVENTORY_PREFERENCES',
      'KITTING.views.ADDRESSES',
    ],
  },
  [ALL_APP_IDS.MANAGED_INVENTORY]: {
    id: ALL_APP_IDS.MANAGED_INVENTORY,
    name: 'Managed Inventory',
    icon: IconArchive,
    path: '/managed-inventory',
    appGroup: ALL_APP_GROUPS.INVENTORY,
    includeInSidebar: true,
    globalEnabled: false,
    isRequiredFor: [ALL_APP_IDS.WAREHOUSE],
    views: {
      [ALL_VIEW_IDS.HANDLING_TASKS]: ALL_VIEWS[ALL_VIEW_IDS.HANDLING_TASKS],
      [ALL_VIEW_IDS.STOCK_LOCATIONS]: ALL_VIEWS[ALL_VIEW_IDS.STOCK_LOCATIONS],
      [ALL_VIEW_IDS.INVENTORY_PREFERENCES]:
        ALL_VIEWS[ALL_VIEW_IDS.INVENTORY_PREFERENCES],
    },
    shortcuts: ['KITTING.views.ADDRESSES'],
  },
  [ALL_APP_IDS.WAREHOUSE]: {
    id: ALL_APP_IDS.WAREHOUSE,
    name: 'Warehouse',
    icon: IconBuildingWarehouse,
    path: MOBILE_INVENTORY_ROUTES.DASHBOARD,
    appGroup: ALL_APP_GROUPS.INVENTORY,
    includeInSidebar: true,
    globalEnabled: false,
    includeInBlended: true,
    features: {
      [ALL_FEATURE_IDS.BULK_CREATE_EXPECTED]:
        ALL_FEATURES[ALL_FEATURE_IDS.BULK_CREATE_EXPECTED],
    },
  },
  [ALL_APP_IDS.PRODUCTION]: {
    id: ALL_APP_IDS.PRODUCTION,
    name: 'Programs',
    icon: IconCalendarEvent,
    path: '/programs',
    deprecatedPath: '/production',
    appGroup: null,
    includeInSidebar: true,
    globalEnabled: false,
  },
  [ALL_APP_IDS.USERS]: {
    id: ALL_APP_IDS.USERS,
    name: 'Users',
    icon: IconUsers,
    path: '/users',
    appGroup: null,
    includeInSidebar: false,
    globalEnabled: false,
    requiredPermissionLevel: appPermissionAccessLevels.read,
    features: {
      [ALL_FEATURE_IDS.APP_LEVEL_PERMISSIONS]:
        ALL_FEATURES[ALL_FEATURE_IDS.APP_LEVEL_PERMISSIONS],
    },
  },
  [ALL_APP_IDS.ORG_SETTINGS]: {
    id: ALL_APP_IDS.ORG_SETTINGS,
    name: 'Organization Settings',
    icon: IconSettings,
    path: '/org',
    appGroup: null,
    includeInSidebar: false,
    globalEnabled: false,
    requiredPermissionLevel: appPermissionAccessLevels.read,
    views: {
      [ALL_VIEW_IDS.ORG_INFO]: ALL_VIEWS[ALL_VIEW_IDS.ORG_INFO],
      [ALL_VIEW_IDS.ORG_PREFERENCES]: ALL_VIEWS[ALL_VIEW_IDS.ORG_PREFERENCES],
      [ALL_VIEW_IDS.ORG_SUBORGS]: ALL_VIEWS[ALL_VIEW_IDS.ORG_SUBORGS],
    },
    features: {
      [ALL_FEATURE_IDS.SUB_ORGS]: ALL_FEATURES[ALL_FEATURE_IDS.SUB_ORGS],
      [ALL_FEATURE_IDS.FILE_ATTACHMENTS]:
        ALL_FEATURES[ALL_FEATURE_IDS.FILE_ATTACHMENTS],
    },
    shortcuts: [
      'MANAGED_INVENTORY.views.INVENTORY_PREFERENCES',
      'KITTING.views.KITTING_PREFERENCES',
      'ORDERS.views.ORDER_PREFERENCES',
      'KITTING.views.ADDRESSES',
      'USERS',
      'BILLING',
      'PROPERTIES.views.TEAMS_PREFERENCES',
    ],
  },
  [ALL_APP_IDS.USER_SETTINGS]: {
    id: ALL_APP_IDS.USER_SETTINGS,
    name: 'Your Settings',
    icon: IconUser,
    path: '/user-settings',
    appGroup: null,
    includeInSidebar: false,
    globalEnabled: true,
    requiredPermissionLevel: appPermissionAccessLevels.edit,
    views: {
      [ALL_VIEW_IDS.PROFILE]: ALL_VIEWS[ALL_VIEW_IDS.PROFILE],
      [ALL_VIEW_IDS.SECURITY]: ALL_VIEWS[ALL_VIEW_IDS.SECURITY],
      [ALL_VIEW_IDS.NOTIFICATIONS]: ALL_VIEWS[ALL_VIEW_IDS.NOTIFICATIONS],
    },
  },
  [ALL_APP_IDS.INTEGRATIONS]: {
    id: ALL_APP_IDS.INTEGRATIONS,
    name: 'Integrations',
    icon: IconPlug,
    path: '/integrations',
    appGroup: null,
    includeInSidebar: false,
    globalEnabled: false,
    views: {
      [ALL_VIEW_IDS.INTEGRATIONS_MARKETPLACE]:
        ALL_VIEWS[ALL_VIEW_IDS.INTEGRATIONS_MARKETPLACE],
      [ALL_VIEW_IDS.INTEGRATIONS_DESIGNER]:
        ALL_VIEWS[ALL_VIEW_IDS.INTEGRATIONS_DESIGNER],
      [ALL_VIEW_IDS.INTEGRATIONS_LEGACY]:
        ALL_VIEWS[ALL_VIEW_IDS.INTEGRATIONS_LEGACY],
    },
    features: {
      [ALL_FEATURE_IDS.BOMS_GOOGLE_SHEETS]:
        ALL_FEATURES[ALL_FEATURE_IDS.BOMS_GOOGLE_SHEETS],
      [ALL_FEATURE_IDS.BOMS_ALTIUM_365]:
        ALL_FEATURES[ALL_FEATURE_IDS.BOMS_ALTIUM_365],
      [ALL_FEATURE_IDS.ORDERS_GOOGLE_SHEETS]:
        ALL_FEATURES[ALL_FEATURE_IDS.ORDERS_GOOGLE_SHEETS],
      [ALL_FEATURE_IDS.PART_STOCK_GOOGLE_SHEETS]:
        ALL_FEATURES[ALL_FEATURE_IDS.PART_STOCK_GOOGLE_SHEETS],
      [ALL_FEATURE_IDS.STOCK_LOTS_GOOGLE_SHEETS]:
        ALL_FEATURES[ALL_FEATURE_IDS.STOCK_LOTS_GOOGLE_SHEETS],
      [ALL_FEATURE_IDS.CUSTOM_PARTS_FILE]:
        ALL_FEATURES[ALL_FEATURE_IDS.CUSTOM_PARTS_FILE],
      [ALL_FEATURE_IDS.STOCK_LOTS_FILE]:
        ALL_FEATURES[ALL_FEATURE_IDS.STOCK_LOTS_FILE],
    },
  },
  [ALL_APP_IDS.BILLING]: {
    id: ALL_APP_IDS.BILLING,
    name: 'Billing',
    icon: IconCash,
    path: '/billing',
    appGroup: null,
    includeInSidebar: false,
    globalEnabled: false,
    views: {
      [ALL_VIEW_IDS.BILLING_USAGE]: ALL_VIEWS[ALL_VIEW_IDS.BILLING_USAGE],
      [ALL_VIEW_IDS.BILLING_INVOICES]: ALL_VIEWS[ALL_VIEW_IDS.BILLING_INVOICES],
      [ALL_VIEW_IDS.BILLING_PLAN]: ALL_VIEWS[ALL_VIEW_IDS.BILLING_PLAN],
      [ALL_VIEW_IDS.BILLING_PAYMENTS]: ALL_VIEWS[ALL_VIEW_IDS.BILLING_PAYMENTS],
    },
    shortcuts: ['ORDERS.views.ORDERS_TABLE'],
  },
  [ALL_APP_IDS.SUPPLIERS]: {
    id: ALL_APP_IDS.SUPPLIERS,
    name: 'Suppliers',
    icon: IconBuildingFactory2,
    path: '/suppliers',
    appGroup: ALL_APP_GROUPS.PROCUREMENT,
    includeInSidebar: true,
    globalEnabled: false,
    requiredPermissionLevel: appPermissionAccessLevels.read,
    views: {
      [ALL_VIEW_IDS.SUPPLIERS]: ALL_VIEWS[ALL_VIEW_IDS.SUPPLIERS],
      [ALL_VIEW_IDS.PURCHASE_RULES]: ALL_VIEWS[ALL_VIEW_IDS.PURCHASE_RULES],
    },
    features: {
      [ALL_FEATURE_IDS.GENERATE_PO]: ALL_FEATURES[ALL_FEATURE_IDS.GENERATE_PO],
      [ALL_FEATURE_IDS.MANAGED_ORDERS]:
        ALL_FEATURES[ALL_FEATURE_IDS.MANAGED_ORDERS],
      [ALL_FEATURE_IDS.CUSTOMER_ORDER_API]:
        ALL_FEATURES[ALL_FEATURE_IDS.CUSTOMER_ORDER_API],
      [ALL_FEATURE_IDS.COFACTR_PAY]: ALL_FEATURES[ALL_FEATURE_IDS.COFACTR_PAY],
      [ALL_FEATURE_IDS.SUPPLIER_PRICING_API]:
        ALL_FEATURES[ALL_FEATURE_IDS.SUPPLIER_PRICING_API],
      [ALL_FEATURE_IDS.SUPPLIER_ORDER_API]:
        ALL_FEATURES[ALL_FEATURE_IDS.SUPPLIER_ORDER_API],
      [ALL_FEATURE_IDS.SUPPLIER_ONLINE_CHECKOUT]:
        ALL_FEATURES[ALL_FEATURE_IDS.SUPPLIER_ONLINE_CHECKOUT],
    },
  },
  [ALL_APP_IDS.REPORTING]: {
    id: ALL_APP_IDS.REPORTING,
    name: 'Reporting',
    icon: IconReportAnalytics,
    path: '/reporting',
    includeInSidebar: true,
    includeInBlended: true,
    globalEnabled: false,
    requiredPermissionLevel: appPermissionAccessLevels.read,
    views: {
      [ALL_VIEW_IDS.REPORTING_APP_SQL_QUERIES]:
        ALL_VIEWS[ALL_VIEW_IDS.REPORTING_APP_SQL_QUERIES],
    },
    features: {
      [ALL_FEATURE_IDS.SQL_QUERIES]: ALL_FEATURES[ALL_FEATURE_IDS.SQL_QUERIES],
    },
  },
  [ALL_APP_IDS.PROPERTIES]: {
    id: ALL_APP_IDS.PROPERTIES,
    name: 'Properties',
    icon: IconTag,
    path: '/properties',
    includeInSidebar: false,
    globalEnabled: false,
    requiredPermissionLevel: appPermissionAccessLevels.read,
    views: {
      [ALL_VIEW_IDS.TEAMS_TABLE]: ALL_VIEWS[ALL_VIEW_IDS.TEAMS_TABLE],
      [ALL_VIEW_IDS.TEAMS_PREFERENCES]:
        ALL_VIEWS[ALL_VIEW_IDS.TEAMS_PREFERENCES],
      [ALL_VIEW_IDS.CUSTOM_PROPERTIES]:
        ALL_VIEWS[ALL_VIEW_IDS.CUSTOM_PROPERTIES],
    },
  },
};

export const noAccessMessages: { [key in appPermissionStates]?: string } = {
  [appPermissionStates.comingSoon]:
    'This app is coming soon. Contact success@cofactr.com for more information.',
  [appPermissionStates.disabled]:
    'Your administrator has disabled this app. Please re-enable in Account settings.',
  [appPermissionStates.unavailable]:
    'This app is not enabled for your account. Please contact success@cofactr.com to request access.',
  [appPermissionStates.noPermission]:
    'Your administrator has not given you access to this account. Please contact them for access.',
};
