import { User } from 'types/user';
import { Org } from 'types/org';
import _ from 'lodash';

export function isCofactrUser({
  user,
  viewAsStaff,
}: {
  user: User | undefined;
  viewAsStaff: boolean | null;
}) {
  if (!user) {
    return false;
  }
  if (!viewAsStaff) {
    return false;
  }
  return _.find(user?.orgs, ['id', user?.org])?.name === 'Cofactr';
}

export function isWarehouseUser(
  user: User | undefined,
  org: string | Org | undefined | null
) {
  if (!user || !org) {
    return false;
  }
  if (
    !user.orgs.find((o) => o.id === (typeof org === 'string' ? org : org.id))
  ) {
    return false;
  }
  if (
    user.orgs
      .find((o) => o.id === (typeof org === 'string' ? org : org.id))
      ?.permissions.some((p) => ['admin', 'warehouse_inventory'].includes(p))
  ) {
    return true;
  }
  return false;
}

export function hasMultipleOrgs(user: User | undefined) {
  if (!user) {
    return false;
  }
  return user.orgs.length > 1;
}

export function isExternalSource(resource: any) {
  return resource?.dataSource != null && resource?.dataSource !== 'cofactr';
}

export function orgFromUserOrgId(
  user: User | undefined,
  org: string | Org | undefined | null
) {
  if (!user || !org) {
    return null;
  }
  if (
    !user.orgs.find((o) => o.id === (typeof org === 'string' ? org : org.id))
  ) {
    return null;
  }
  return user.orgs.find(
    (o) => o.id === (typeof org === 'string' ? org : org.id)
  );
}
// Get a list of org names from an array of any objects that have an org property
export function getOrgNames(
  user: User | undefined,
  list: { org?: Org['id'] | Org | undefined | null }[]
) {
  return _.chain(list)
    .map('org')
    .compact()
    .uniq()
    .map((o) => orgFromUserOrgId(user, o)?.name)
    .join(', ')
    .value();
}
