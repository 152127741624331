import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { Redirect } from 'react-router6-redirect';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import OrgGuard from 'utils/route-guard/OrgGuard';
import { DASHBOARD_ROUTES } from 'routes/constants';

import { ALL_APPS, ALL_VIEW_IDS, ALL_VIEWS } from 'constants/appConstants';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import NoSubOrgRouteWrapper from 'views/pages/components/NoSubOrgRouteWrapper';
import HotglueProvider from 'contexts/HotglueProvider';

// Dashboard
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// Part Search App
const PartsSearch = Loadable(
  lazy(() => import('views/parts/PartsSearchTable'))
);

// Part Library App
const PartLayout = Loadable(lazy(() => import('views/parts/PartLayout')));
const PartLibrary = Loadable(lazy(() => import('views/parts/PartLibrary')));

// Watchlist App
const PartsWatchlist = Loadable(
  lazy(() => import('views/parts/PartsWatchlist'))
);

// Quotes App
const PurchasingPartQuotes = Loadable(
  lazy(() => import('views/purchasing/PurchasingPartQuotes'))
);

// Procurement App
const ProcurementDashboard = Loadable(
  lazy(() => import('views/procurement/Dashboard'))
);
const ProcurementInvoice = Loadable(
  lazy(() => import('views/procurement/Invoice'))
);
const ProcurementInvoices = Loadable(
  lazy(() => import('views/procurement/Invoices'))
);
const ProcurementPackingSlip = Loadable(
  lazy(() => import('views/procurement/PackingSlip'))
);
const ProcurementPackingSlips = Loadable(
  lazy(() => import('views/procurement/PackingSlips'))
);
const ProcurementPurchaseOrder = Loadable(
  lazy(() => import('views/procurement/PurchaseOrder'))
);
const ProcurementPurchaseOrders = Loadable(
  lazy(() => import('views/procurement/PurchaseOrders'))
);
const ProcurementPurchaseRequests = Loadable(
  lazy(() => import('views/procurement/PurchaseRequests'))
);
const ProcurementDocAiInbox = Loadable(
  lazy(() => import('views/procurement/DocInbox'))
);
const ProcurementPreferences = Loadable(
  lazy(() => import('views/procurement/Preferences'))
);

// Orders App
const PurchasingOrdersProcess = Loadable(
  lazy(() => import('views/purchasing/PurchasingOrdersProcess'))
);
const PurchasingOrdersStatus = Loadable(
  lazy(() => import('views/purchasing/PurchasingOrdersStatus'))
);
const PurchasingOrdersTable = Loadable(
  lazy(() => import('views/purchasing/PurchasingOrders'))
);
const PurchasingOrder = Loadable(lazy(() => import('views/purchasing/Order')));
const PurchasingSnapshotsTable = Loadable(
  lazy(() => import('views/purchasing/PurchasingSnapshots'))
);
const PurchasingSnapshotWrapper = Loadable(
  lazy(
    () =>
      import('views/purchasing/PurchasingSnapshot/PurchasingSnapshotWrapper')
  )
);
const PurchasingOrderPreferences = Loadable(
  lazy(() => import('views/purchasing/OrderPreferences'))
);

// Suppliers App
const Suppliers = Loadable(lazy(() => import('views/suppliers/Suppliers')));
const PurchaseRules = Loadable(
  lazy(() => import('views/suppliers/PurchaseRules'))
);

// Kitting App
const InventoryShipments = Loadable(
  lazy(() => import('views/inventory/InventoryShipments'))
);
const InventoryShipment = Loadable(
  lazy(() => import('views/inventory/InventoryShipment'))
);
const StockLocations = Loadable(
  lazy(() => import('views/inventory/StockLocations'))
);
const InventoryAddresses = Loadable(
  lazy(() => import('views/inventory/InventoryAddresses'))
);
const ShipmentPreferences = Loadable(
  lazy(() => import('views/inventory/ShippingPreferences'))
);

// Warehouse App
// See MobileInventoryRoute.tsx

// Managed Inventory
const InventoryHandling = Loadable(
  lazy(() => import('views/inventory/InventoryHandling'))
);
const InventoryPreferences = Loadable(
  lazy(() => import('views/org/Preferences/InventoryPreferences'))
);

// Boms
const Boms = Loadable(lazy(() => import('views/boms/Boms')));
const BomWrapper = Loadable(lazy(() => import('views/boms/Bom/BomWrapper')));

// Stock App
const InventoryParts = Loadable(
  lazy(() => import('views/inventory/InventoryParts'))
);
const InventoryPart = Loadable(
  lazy(() => import('views/inventory/InventoryPart/InventoryPart'))
);
const InventoryStockLots = Loadable(
  lazy(() => import('views/inventory/InventoryStockLots'))
);
const InventoryStockLot = Loadable(
  lazy(() => import('views/inventory/StockLot/InventoryStockLot'))
);
const InventoryAllocations = Loadable(
  lazy(() => import('views/inventory/InventoryAllocations'))
);

// Production App
const Runs = Loadable(
  lazy(() => import('views/production/views/ProductionRuns'))
);
const Program = Loadable(
  lazy(() => import('views/production/views/ProgramContainer'))
);

// Users App
const Users = Loadable(lazy(() => import('views/org/Users')));

// Org Settings App
const OrgInfo = Loadable(lazy(() => import('views/org/OrgInfo')));
const OrgPreferences = Loadable(
  lazy(() => import('views/org/Preferences/GeneralPreferences'))
);
const OrgSubOrgs = Loadable(lazy(() => import('views/org/SubOrgs')));

// User Settings App
const UserProfile = Loadable(
  lazy(() => import('views/account/UserAccountProfile'))
);
const UserSecurity = Loadable(
  lazy(() => import('views/account/UserAccountSecurity'))
);
const UserNotifications = Loadable(
  lazy(() => import('views/account/UserAccountNotifications'))
);

// Billing App
const BillingInvoices = Loadable(lazy(() => import('views/billing/invoices')));
const BillingPlan = Loadable(lazy(() => import('views/billing/plan')));
const BillingUsage = Loadable(lazy(() => import('views/billing/usage')));
const BillingPayments = Loadable(lazy(() => import('views/billing/payments')));

// Integrations App
const IntegrationsLegacy = Loadable(
  lazy(() => import('views/integrations/views/Legacy'))
);

const IntegrationsMarketplace = Loadable(
  lazy(() => import('views/integrations/views/Marketplace'))
);

const IntegrationsDesigner = Loadable(
  lazy(() => import('views/integrations/views/Designer'))
);

// Reporting App
const ReportingDataSql = Loadable(lazy(() => import('views/reporting/Sql')));

// Properties App
const TeamsTable = Loadable(
  lazy(() => import('views/properties/Teams/TeamsTable'))
);
const TeamsPreferences = Loadable(
  lazy(() => import('views/properties/Teams/TeamPreferences'))
);
const CustomProperties = Loadable(
  lazy(() => import('views/properties/CustomProperties/CustomPropertiesTable'))
);

// Other
const Error = Loadable(lazy(() => import('views/pages/Error')));

const AppRoutes: RouteObject = {
  path: '/',
  element: (
    <AuthGuard>
      <OrgGuard>
        <MainLayout />
      </OrgGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: DASHBOARD_ROUTES.DASHBOARD,
      element: <Dashboard />,
    },
    {
      path: `${ALL_APPS.PART_SEARCH.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.PART_SEARCH.id }}
          >
            <PartsSearch />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.PART_SEARCH.path}/:partId`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.PART_SEARCH.id }}
          >
            <PartLayout />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.PART_LIBRARY.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.PART_LIBRARY.id,
            }}
          >
            <Navigate
              to={`${ALL_APPS.PART_LIBRARY.views?.PART_ALL?.path}`}
              replace
            />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.PART_LIBRARY.views?.PART_ALL?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.PART_LIBRARY.id,
              view: ALL_VIEW_IDS.PART_ALL,
            }}
          >
            <PartLibrary />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.WATCHLIST.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.WATCHLIST.id }}
          >
            <PartsWatchlist />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.BOMS.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.BOMS.id }}>
            <Boms />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.BOMS.path}/:bomId`,
      element: (
        <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.BOMS.id }}>
          <BomWrapper />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.QUOTES.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.QUOTES.id }}
          >
            <PurchasingPartQuotes />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.PROCUREMENT.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.PROCUREMENT.id,
          }}
        >
          <Navigate to={`${ALL_VIEWS.PROCUREMENT_DASHBOARD.path}`} replace />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.PROCUREMENT_DASHBOARD.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
        >
          <ProcurementDashboard />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.PURCHASE_REQUESTS.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
        >
          <ProcurementPurchaseRequests />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.PROCUREMENT_APP_DOC_AI.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
        >
          <ProcurementDocAiInbox />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.PROCUREMENT_PREFERENCES.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
        >
          <ProcurementPreferences />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.PURCHASE_ORDERS.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
          >
            <ProcurementPurchaseOrders />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.PURCHASE_ORDERS.path}/:purchaseId`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
        >
          <ProcurementPurchaseOrder />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.PROCUREMENT_APP_INVOICES.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
          >
            <ProcurementInvoices />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.PROCUREMENT_APP_INVOICES.path}/:invoiceId`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
          >
            <ProcurementInvoice />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.PROCUREMENT_APP_PACKING_SLIPS.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
          >
            <ProcurementPackingSlips />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.PROCUREMENT_APP_PACKING_SLIPS.path}/:packingSlipId`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
          >
            <ProcurementPackingSlip />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORDERS.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.ORDERS.id,
            }}
          >
            <Navigate
              to={`${ALL_APPS.ORDERS.views?.YOUR_ORDERS?.path}`}
              replace
            />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORDERS.path}/:orderId`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.ORDERS.id }}
          >
            <PurchasingOrder />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORDERS.views?.ORDERS_STATUS?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.ORDERS.id }}
          >
            <PurchasingOrdersStatus />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORDERS.views?.ORDERS_TABLE?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.ORDERS.id }}
          >
            <PurchasingOrdersTable />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORDERS.views?.PROCESS_ORDERS?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.ORDERS.id,
              view: ALL_VIEW_IDS.PROCESS_ORDERS,
            }}
          >
            <PurchasingOrdersProcess />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORDERS.views?.ORDERS_TABLE?.path}/:orderId`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.ORDERS.id }}
          >
            <PurchasingOrder />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORDERS.views?.YOUR_ORDERS?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.ORDERS.id }}
          >
            <PurchasingSnapshotsTable />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORDERS.views?.YOUR_ORDERS?.path}/:snapshotId`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.ORDERS.id }}
          >
            <PurchasingSnapshotWrapper />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORDERS.views?.ORDER_PREFERENCES?.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.ORDERS.id,
            view: ALL_VIEW_IDS.ORDER_PREFERENCES,
          }}
        >
          <PurchasingOrderPreferences />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.SUPPLIERS.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.SUPPLIERS.id }}
          >
            <Navigate
              to={`${ALL_APPS.SUPPLIERS.views?.SUPPLIERS?.path}`}
              replace
            />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.SUPPLIERS.views?.SUPPLIERS?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.SUPPLIERS.id }}
          >
            <Suppliers />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.SUPPLIERS.views?.PURCHASE_RULES?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.SUPPLIERS.id,
              view: ALL_VIEW_IDS.PURCHASE_RULES,
            }}
          >
            <PurchaseRules />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.KITTING.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.KITTING.id,
            }}
          >
            <Navigate
              to={`${ALL_APPS.KITTING.views?.SHIPMENTS?.path}`}
              replace
            />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.KITTING.views?.SHIPMENTS!.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.KITTING.id,
              view: ALL_VIEW_IDS.SHIPMENTS,
            }}
          >
            <InventoryShipments />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.KITTING.views?.SHIPMENTS?.path}/:shipmentId`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.KITTING.id,
              view: ALL_VIEW_IDS.SHIPMENTS,
            }}
          >
            <InventoryShipment />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.ADDRESSES.deprecatedPath}`,
      element: <Redirect to={`${ALL_VIEWS.ADDRESSES.path}`} />,
    },
    {
      path: `${ALL_VIEWS.ADDRESSES.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.KITTING.id,
              view: ALL_VIEW_IDS.ADDRESSES,
            }}
          >
            <InventoryAddresses />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },

    {
      path: `${ALL_APPS.KITTING.views?.KITTING_PREFERENCES?.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.KITTING.id,
            view: ALL_VIEW_IDS.KITTING_PREFERENCES,
          }}
        >
          <ShipmentPreferences />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.MANAGED_INVENTORY.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.MANAGED_INVENTORY.id,
            }}
          >
            <Navigate
              to={`${ALL_APPS.MANAGED_INVENTORY.views?.HANDLING_TASKS?.path}`}
              replace
            />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.MANAGED_INVENTORY.views?.HANDLING_TASKS?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.MANAGED_INVENTORY.id,
              view: ALL_VIEW_IDS.HANDLING_TASKS,
            }}
          >
            <InventoryHandling />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.MANAGED_INVENTORY.views?.STOCK_LOCATIONS?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.MANAGED_INVENTORY.id,
              view: ALL_VIEW_IDS.STOCK_LOCATIONS,
            }}
          >
            <StockLocations />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },

    {
      path: `${ALL_APPS.MANAGED_INVENTORY.views?.INVENTORY_PREFERENCES?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.MANAGED_INVENTORY.id,
              view: ALL_VIEW_IDS.INVENTORY_PREFERENCES,
            }}
          >
            <InventoryPreferences />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.STOCK.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.STOCK.id,
            }}
          >
            <Navigate
              to={`${ALL_APPS.STOCK.views?.PART_STOCK?.path}`}
              replace
            />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.STOCK.views?.PART_STOCK?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.STOCK.id,
              view: ALL_VIEW_IDS.PART_STOCK,
            }}
          >
            <InventoryParts />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.STOCK.views?.PART_STOCK?.path}/:partId`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.STOCK.id,
              view: ALL_VIEW_IDS.PART_STOCK,
            }}
          >
            <InventoryPart />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.STOCK.views?.STOCK_LOTS?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.STOCK.id,
              view: ALL_VIEW_IDS.STOCK_LOTS,
            }}
          >
            <InventoryStockLots />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.STOCK.views?.STOCK_LOTS?.path}/:stockLotId`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.STOCK.id,
              view: ALL_VIEW_IDS.STOCK_LOTS,
            }}
          >
            <InventoryStockLot />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.STOCK.views?.ALLOCATIONS?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.STOCK.id,
              view: ALL_VIEW_IDS.ALLOCATIONS,
            }}
          >
            <InventoryAllocations />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.PRODUCTION.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.PRODUCTION.id }}
          >
            <Runs />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.PRODUCTION.path}/:runId`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.PRODUCTION.id }}
          >
            <Program />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.USERS.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.USERS.id }}>
            <Users />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORG_SETTINGS.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.ORG_SETTINGS.id,
          }}
        >
          <Navigate
            to={`${ALL_APPS.ORG_SETTINGS.views?.ORG_INFO?.path}`}
            replace
          />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORG_SETTINGS.views?.ORG_INFO?.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.ORG_SETTINGS.id,
            view: ALL_VIEW_IDS.ORG_INFO,
          }}
        >
          <OrgInfo />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORG_SETTINGS.views?.ORG_PREFERENCES?.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.ORG_SETTINGS.id }}
        >
          <OrgPreferences />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.ORG_SETTINGS.views?.ORG_SUBORGS?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.ORG_SETTINGS.id,
              view: ALL_VIEW_IDS.ORG_SUBORGS,
            }}
          >
            <NoSubOrgRouteWrapper>
              <OrgSubOrgs />
            </NoSubOrgRouteWrapper>
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.USER_SETTINGS.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{ app: ALL_APPS.USER_SETTINGS.id }}
          >
            <Navigate
              to={`${ALL_APPS.USER_SETTINGS.views?.PROFILE?.path}`}
              replace
            />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.USER_SETTINGS.views?.PROFILE?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.USER_SETTINGS.id,
              view: ALL_VIEW_IDS.PROFILE,
            }}
          >
            <UserProfile />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.USER_SETTINGS.views?.SECURITY?.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.USER_SETTINGS.id,
            view: ALL_VIEW_IDS.SECURITY,
          }}
        >
          <UserSecurity />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.USER_SETTINGS.views?.NOTIFICATIONS?.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.USER_SETTINGS.id,
            view: ALL_VIEW_IDS.NOTIFICATIONS,
          }}
        >
          <UserNotifications />
        </PermissionsRouteWrapper>
      ),
    },

    {
      path: `${ALL_APPS.BILLING.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.BILLING.id,
            view: ALL_VIEW_IDS.BILLING_USAGE,
          }}
        >
          <Navigate
            to={`${ALL_APPS.BILLING.views?.BILLING_USAGE?.path}`}
            replace
          />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.BILLING.views?.BILLING_USAGE?.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.BILLING.id,
            view: ALL_VIEW_IDS.BILLING_USAGE,
          }}
        >
          <NoSubOrgRouteWrapper>
            <BillingUsage />
          </NoSubOrgRouteWrapper>
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.BILLING.views?.BILLING_PLAN?.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.BILLING.id,
            view: ALL_VIEW_IDS.BILLING_PLAN,
          }}
        >
          <NoSubOrgRouteWrapper>
            <BillingPlan />
          </NoSubOrgRouteWrapper>
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.BILLING.views?.BILLING_INVOICES?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.BILLING.id,
              view: ALL_VIEW_IDS.BILLING_INVOICES,
            }}
          >
            <NoSubOrgRouteWrapper>
              <BillingInvoices />
            </NoSubOrgRouteWrapper>
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.BILLING.views?.BILLING_PAYMENTS?.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.BILLING.id,
            view: ALL_VIEW_IDS.BILLING_PAYMENTS,
          }}
        >
          <NoSubOrgRouteWrapper>
            <BillingPayments />
          </NoSubOrgRouteWrapper>
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.INTEGRATIONS.views?.INTEGRATIONS_LEGACY?.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.INTEGRATIONS.id,
              view: ALL_VIEW_IDS.INTEGRATIONS_LEGACY,
            }}
          >
            <HotglueProvider>
              <IntegrationsLegacy />
            </HotglueProvider>
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_APPS.INTEGRATIONS.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.INTEGRATIONS.id,
          }}
        >
          <Navigate
            to={`${ALL_APPS.INTEGRATIONS.views?.INTEGRATIONS_MARKETPLACE?.path}`}
            replace
          />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.INTEGRATIONS.views?.INTEGRATIONS_MARKETPLACE?.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.INTEGRATIONS.id,
            view: ALL_VIEW_IDS.INTEGRATIONS_MARKETPLACE,
          }}
        >
          <IntegrationsMarketplace />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.INTEGRATIONS.views?.INTEGRATIONS_DESIGNER?.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.INTEGRATIONS.id,
            view: ALL_VIEW_IDS.INTEGRATIONS_DESIGNER,
          }}
        >
          <IntegrationsDesigner />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.REPORTING.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.REPORTING.id,
            view: ALL_VIEW_IDS.REPORTING_APP_SQL_QUERIES,
          }}
        >
          <Navigate
            to={`${ALL_VIEWS.REPORTING_APP_SQL_QUERIES.path}`}
            replace
          />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.REPORTING_APP_SQL_QUERIES.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.REPORTING.id,
            view: ALL_VIEW_IDS.REPORTING_APP_SQL_QUERIES,
          }}
        >
          <ReportingDataSql />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_APPS.PROPERTIES.path}`,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.PROPERTIES.id,
            view: ALL_VIEW_IDS.TEAMS_TABLE,
          }}
        >
          <Navigate to={`${ALL_VIEWS.TEAMS_TABLE.path}`} replace />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.TEAMS_TABLE.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.PROPERTIES.id,
              view: ALL_VIEW_IDS.TEAMS_TABLE,
            }}
          >
            <TeamsTable />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.TEAMS_PREFERENCES.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.PROPERTIES.id,
              view: ALL_VIEW_IDS.TEAMS_PREFERENCES,
            }}
          >
            <TeamsPreferences />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: `${ALL_VIEWS.CUSTOM_PROPERTIES.path}`,
      element: (
        <MainCardWrapper>
          <PermissionsRouteWrapper
            permissionScope={{
              app: ALL_APPS.PROPERTIES.id,
              view: ALL_VIEW_IDS.CUSTOM_PROPERTIES,
            }}
          >
            <CustomProperties />
          </PermissionsRouteWrapper>
        </MainCardWrapper>
      ),
    },
    {
      path: DASHBOARD_ROUTES.ERROR,
      element: <Error />,
    },
  ],
};

export default AppRoutes;
