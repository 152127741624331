import _ from 'lodash';
import {
  mslAllowedExposureHours,
  PackagingOptions,
  Shipment,
  ShipmentBase,
  ShipmentLite,
  ShipmentType,
  StockLocation,
  StockLocationType,
} from 'types/inventory';
import { Org } from 'types/org';
import { ProductionRun } from 'types/production';
import { GenericObject } from 'types';
import { hoursToHoursOrDays } from 'utils/dates';
import { Part } from 'types/part';
import { packageTypes } from 'ui-component/CustomPartDialog/constants';
import { Dispatch, SetStateAction } from 'react';
import { PropertySubsetValidator } from 'types/api';

// @ts-ignore
export const camelize = (obj): any =>
  _.transform(obj, (acc, value, key, target) => {
    // @ts-ignore
    const camelKey = _.isArray(target) ? key : _.camelCase(key);
    // @ts-ignore
    acc[camelKey] = _.isObject(value) ? camelize(value) : value;
  });

// @ts-ignore
export const camelizeVars = (obj): any =>
  _.transform(obj, (acc, value, key, target) => {
    if (key === 'var') {
      acc[key] = _.camelCase(value);
    } else {
      acc[key] = _.isObject(value) ? camelizeVars(value) : value;
    }
  });

// @ts-ignore
export const snakeCase = (obj) =>
  _.transform(obj, (acc, value, key, target) => {
    // @ts-ignore
    const snakeKey = _.isArray(target) ? key : _.snakeCase(key);
    // @ts-ignore
    acc[snakeKey] = _.isObject(value) ? snakeCase(value) : value;
  });

export const removeUndefined = (obj: object): any => {
  if (Array.isArray(obj)) {
    return _.map(obj, removeUndefined);
  }
  return _.omitBy(obj, (val) => val === undefined);
};

// type=facility for which customer_location=False or customer_location=True and ext_customer_id=current org
export const stockLocationsDefaultFilter = (rows: StockLocation[]) =>
  _.filter(rows, (row) => row.locationType === StockLocationType.FACILITY);

type errHandler = (
  errMessage: string
) => void | Dispatch<SetStateAction<string | null>>;

export type ErrorType = Error & {
  status: number;
  originalStatus?: number;
  data?:
    | string
    | {
        message?: string;
      };
  message?: string;
};

export const generalErrorMessage = `There was an error completing this action. We've alerted our team, and they're looking into it. Please refresh the page, or try again later.`;

export const handleErr = (error: unknown, errHandler: errHandler) => {
  const err = error as ErrorType;
  if (
    err?.status === 404 ||
    err.status >= 500 ||
    (err?.originalStatus && err.originalStatus >= 500)
  ) {
    errHandler(generalErrorMessage);
  } else if (
    err?.data &&
    typeof err?.data === 'string' &&
    err.data?.includes('<!DOCTYPE html>')
  ) {
    errHandler(generalErrorMessage);
  } else if (
    typeof err?.data === 'object' &&
    typeof err.data.message === 'string'
  ) {
    errHandler(err?.data?.message);
  } else if (typeof err?.message === 'string') {
    errHandler(err?.message);
  } else if (typeof err?.data === 'string') {
    errHandler(err?.data);
  } else {
    errHandler(generalErrorMessage);
  }
  throw err;
};

export function getCookie(name: string) {
  // It's warning about unnecessary escapes and regex scares me so I'm murdering TS instead
  function escape(s: string) {
    // eslint-disable-next-line
    return s.replace(/([.*+?^$(){}|\[\]\/\\])/g, '\\$1');
  }
  const match = document.cookie.match(
    // eslint-disable-next-line
    RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)')
  );
  return match ? match[1] : null;
}

export const formatPrice = (
  value: number | string | undefined | null,
  precision?: number
) =>
  value
    ? parseFloat(value as unknown as string).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: precision || 6,
        minimumFractionDigits: precision || 2,
      })
    : '-';

export const copyClipboard = (value: string) =>
  navigator.clipboard.writeText(value);

export function checkIfValidUUID(str: string) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
}

// Written for Segment track events
// Not intended for Group, Page, Identify, etc
export function analyticsTrack(eventName: string, eventData: GenericObject) {
  if (window.analytics) {
    analytics.track(eventName, eventData);
  }
}

export function formatOrReturnDefault({
  value,
  formatFunction,
  defaultValue = '-',
}: {
  value: any;
  formatFunction: Function;
  defaultValue?: string;
}) {
  return value ? formatFunction(value) : defaultValue;
}

export function mslRemainingFloorLife(msl: string, mslFloorHours: number) {
  if (!msl) {
    return null;
  }
  if (msl === '1') {
    return Infinity;
  }
  return (
    _.get(mslAllowedExposureHours, _.toLower(msl), 0) - (mslFloorHours || 0)
  );
}

export function mslFloorLifeText(
  msl: string | null | undefined,
  mslFloorHours: number | null | undefined
) {
  const mslHours = !mslFloorHours ? 0 : mslFloorHours;
  if (!msl) {
    return 'Unlimited';
  }
  if (!mslRemainingFloorLife(msl, mslHours)) {
    return 'Unlimited';
  }
  if (mslRemainingFloorLife(msl, mslHours) === Infinity) {
    return 'Unlimited';
  }
  if ((mslRemainingFloorLife(msl, mslHours) || 0) <= 23) {
    return 'Bake Required';
  }
  const timeRemaining = hoursToHoursOrDays(
    mslRemainingFloorLife(msl, mslHours) || 0
  );
  return `${timeRemaining.value.toLocaleString()} ${pluralizeNoun(
    timeRemaining.unit,
    timeRemaining.value
  )}`;
}

export function mslFloorLifeColumnText(value: number | null) {
  if (!value) {
    return 'Unlimited';
  }
  if (value === Infinity) {
    return 'Unlimited';
  }
  if (value <= 23) {
    return 'Bake Required';
  }
  const timeRemaining = hoursToHoursOrDays(value || 0);
  return `${timeRemaining.value.toLocaleString()} ${pluralizeNoun(
    timeRemaining.unit,
    timeRemaining.value
  )}`;
}

export function getDefaultShippingMethod({
  org,
  productionRun,
}: {
  org?: Org;
  productionRun?: PropertySubsetValidator<
    ProductionRun,
    'defaultShippingCarrier' | 'defaultShippingService'
  >;
}) {
  if (productionRun) {
    return productionRun?.defaultShippingService &&
      productionRun?.defaultShippingCarrier
      ? `${productionRun.defaultShippingCarrier}_${productionRun.defaultShippingService}`
      : '';
  }
  if (org) {
    return org?.defaultShippingService && org?.defaultShippingCarrier
      ? `${org.defaultShippingCarrier}_${org.defaultShippingService}`
      : '';
  }
  return '';
}

export function getShipmentCarrierAndService(method: string) {
  const [defaultShippingCarrier, defaultShippingService] = method.split('_');
  return { defaultShippingCarrier, defaultShippingService };
}

export function pluralizeNoun(word: string, value: number) {
  return `${word}${value === 1 ? '' : 's'}`;
}

export function pluralizeWordOption(
  singular: string,
  plural: string,
  value: number
) {
  return value === 1 ? singular : plural;
}

export function formatListWithAnd(list: string[]) {
  const formatter = new Intl.ListFormat('gb', {
    style: 'long',
    type: 'conjunction',
  });
  return formatter.format(list);
}

export function formatListWithMore(list: string[], trimLength: number) {
  const compactList = _.compact(list);
  if (!compactList.length) {
    return '';
  }
  if (compactList.length <= trimLength) {
    return _.join(compactList, ', ');
  }
  return `${_.join(compactList.slice(0, trimLength), ', ')} +${(
    compactList.length - trimLength
  ).toLocaleString('en-US')} more`;
}

export function findDuplicatePartsInRows(rowData: any[]) {
  // process lines to find any duplicate parts
  const allPartIds = _.compact(
    _.flatten(
      _.map(
        rowData.filter((r) => !r.dnp && !r.ignore),
        (p) => [
          typeof p.part === 'string' ? p.part : p.part?.id,
          ..._.map(p.alts, 'id'),
        ]
      )
    )
  );
  const countedIds = _.countBy(allPartIds);
  const duplicateIds = _.pickBy(countedIds, (value) => value > 1);
  const duplicateMpns = _.compact(
    Object.keys(duplicateIds).map(
      (id) =>
        rowData.find((r) =>
          typeof r.part === 'string' ? r.part === id : r.part?.id === id
        )?.part
    )
  ).map((p) => (typeof p === 'string' ? p : p.mpn));

  return duplicateMpns;
}

export function duplicatePartsFromRows(
  rowData: {
    part: Part | null | string;
    alts: Part[];
    dnp?: boolean;
    ignore?: boolean;
  }[]
) {
  return _.chain(rowData)
    .filter((r) => !r.dnp && !r.ignore && !!r.part)
    .map((p) => [
      typeof p.part === 'string' ? p.part : p.part?.id,
      ..._.map(p.alts, 'id'),
    ])
    .flatten()
    .countBy()
    .pickBy((value) => value > 1)
    .keys()
    .map(
      (id) =>
        rowData.find((r) =>
          typeof r.part === 'string' ? r.part === id : r.part?.id === id
        )?.part
    )
    .compact()
    .map((p) => (typeof p === 'string' ? null : p))
    .compact()
    .value();
}

export function overageWarningPartsFromRows(
  rowData: {
    part: Part | null | string;
    dnp?: boolean;
    ignore?: boolean;
  }[]
) {
  return _.chain(rowData)
    .filter((r) => !r.dnp && !r.ignore)
    .filter((r) => typeof r.part !== 'string' && !!r.part)
    .map((r) => r.part as Part)
    .filter(
      (p) => (!p.package && p.terminationType === 'SMT') || !p.terminationType
    )
    .value();
}

export function getShipmentPickerLabel(
  shipment: Shipment | ShipmentBase | ShipmentLite
) {
  if (shipment.shipmentType === ShipmentType.KIT_WITHIN_WAREHOUSE) {
    return `Kit at ${
      shipment.shipFrom?.externalName || shipment.shipFrom?.name
    } (${shipment.shipmentCode}) ${shipment.notes}`;
  }
  return `${
    shipment.shipFrom?.externalName || shipment.shipFrom?.name || '-'
  } → ${
    shipment.shipTo?.externalName ||
    shipment.shipTo?.name ||
    shipment.shipTo?.address?.company ||
    shipment.shipTo?.address?.name ||
    shipment.address?.company ||
    shipment.address?.name ||
    '-'
  } (${shipment.shipmentCode}) ${shipment.notes}`;
}

export const packageFromDescription = (description: string) => {
  let foundPackage = null;
  _.forEach(packageTypes, (p) => {
    if (_.includes(description, p)) {
      foundPackage = p;
      return false;
    }
    return true;
  });
  return foundPackage;
};

export function removeNonAlphanumericOrPeriod(
  inputString: string | null | undefined
) {
  if (!inputString) {
    return inputString;
  }
  return inputString.replace(/[^a-zA-Z0-9.]/g, '');
}

export const packageFromOfferPackage = (offerPackage: string | null) => {
  if (!offerPackage) return null;
  const lowerPackage = _.toLower(offerPackage);
  if (lowerPackage.includes(PackagingOptions.reel))
    return PackagingOptions.reel;
  if (lowerPackage.includes('tape')) return PackagingOptions.tapeSingle;
  if (lowerPackage.includes(PackagingOptions.tray))
    return PackagingOptions.tray;
  if (lowerPackage.includes(PackagingOptions.tube))
    return PackagingOptions.tube;
  return null;
};

export const downloadFile = (
  blob: Blob,
  filename: string,
  fileExtension: string
) => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${filename}.${fileExtension}`;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
