import {
  CreateUpdateTeamPayload,
  CreateUpdateTeamTypePayload,
  Team,
  TeamLite,
  TeamType,
} from 'types/properties';
import { apiSlice } from 'store/slices/api';
import { URLS } from 'store/constant';
import { Org } from 'types/org';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createTeam: builder.mutation<
      Team,
      { payload: CreateUpdateTeamPayload; overrideOrgId?: Org['id'] }
    >({
      query: ({ payload, overrideOrgId }) => ({
        url: URLS.TEAMS,
        method: 'POST',
        body: payload,
        headers: {
          'x-org-id': overrideOrgId,
        },
      }),
      invalidatesTags: [{ type: 'Teams', id: 'LIST' }],
    }),
    updateTeam: builder.mutation<
      Team,
      { id: Team['id']; payload: CreateUpdateTeamPayload }
    >({
      query: ({ id, payload }) => ({
        url: URLS.TEAM(id),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Teams', id },
        { type: 'Teams', id: 'LIST' },
      ],
    }),
    getTeam: builder.query<Team, Team['id']>({
      query: (teamId) => ({
        url: URLS.TEAM(teamId),
      }),
      providesTags: (__, ___, teamId) => [{ type: 'Teams', id: teamId }],
    }),
    getTeams: builder.query<TeamLite[], void>({
      query: () => ({
        url: URLS.TEAMS,
      }),
      providesTags: () => [{ type: 'Teams', id: 'LIST' }],
    }),
    archiveTeam: builder.mutation<Team, Team['id']>({
      query: (id) => ({
        url: URLS.ARCHIVE_TEAM(id),
        method: 'POST',
      }),
      invalidatesTags: (result, __, teamId) =>
        result
          ? [
              { type: 'Teams', id: teamId },
              { type: 'Teams', id: 'LIST' },
            ]
          : [],
    }),
    unarchiveTeam: builder.mutation<Team, Team['id']>({
      query: (id) => ({
        url: URLS.UNARCHIVE_TEAM(id),
        method: 'POST',
      }),
      invalidatesTags: (result, __, teamId) =>
        result
          ? [
              { type: 'Teams', id: teamId },
              { type: 'Teams', id: 'LIST' },
            ]
          : [],
    }),
    getTeamTypes: builder.query<TeamType[], void>({
      query: () => ({
        url: URLS.TEAM_TYPES,
      }),
      providesTags: () => [{ type: 'TeamTypes', id: 'LIST' }],
    }),
    getTeamType: builder.query<TeamType, TeamType['id']>({
      query: (teamTypeId) => ({
        url: URLS.TEAM_TYPE(teamTypeId),
      }),
      providesTags: (__, ___, teamTypeId) => [
        { type: 'TeamTypes', id: teamTypeId },
      ],
    }),
    createTeamType: builder.mutation<
      TeamType,
      { payload: CreateUpdateTeamTypePayload; overrideOrgId?: Org['id'] }
    >({
      query: ({ payload, overrideOrgId }) => ({
        url: URLS.TEAM_TYPES,
        method: 'POST',
        body: payload,
        headers: {
          'x-org-id': overrideOrgId,
        },
      }),
      invalidatesTags: [{ type: 'TeamTypes', id: 'LIST' }],
    }),
    updateTeamType: builder.mutation<
      TeamType,
      { id: TeamType['id']; payload: CreateUpdateTeamTypePayload }
    >({
      query: ({ id, payload }) => ({
        url: URLS.TEAM_TYPE(id),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, __, { id }) =>
        result
          ? [
              { type: 'TeamTypes', id },
              { type: 'TeamTypes', id: 'LIST' },
            ]
          : [],
    }),
  }),
});

export const {
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useGetTeamQuery,
  useLazyGetTeamQuery,
  useGetTeamsQuery,
  useArchiveTeamMutation,
  useUnarchiveTeamMutation,
  useGetTeamTypesQuery,
  useLazyGetTeamTypeQuery,
  useCreateTeamTypeMutation,
  useUpdateTeamTypeMutation,
} = extendedApiSlice;
