import _ from 'lodash';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { analyticsTrack } from 'utils/functions';
import { forwardRef, ForwardedRef, memo } from 'react';
import { PermissionScope } from 'types/apps';
import { Tooltip } from '@mui/material';
import usePermissionStateAndCopyHook from 'hooks/usePermissionStateAndCopyHook';

export interface SegmentLoadingButtonProps extends LoadingButtonProps {
  trackingName?: string;
  permissionScope?: PermissionScope;
  tooltip?: string;
}

const SegmentLoadingButton = forwardRef(
  (
    {
      trackingName,
      permissionScope,
      tooltip,
      children,
      ...rest
    }: SegmentLoadingButtonProps,
    ref: ForwardedRef<any>
  ) => {
    const { permissionDisabled, disabledCopy } = usePermissionStateAndCopyHook({
      permissionScope,
    });
    // TODO: we should get the actual click handler types in here properly
    function handleClick(...args: any) {
      if (trackingName) {
        const trackingString =
          trackingName || (typeof children === 'string' ? children : '');
        analyticsTrack(`${trackingString}`, {
          props: _.pick(rest, ['color', 'size', 'variant', 'type']),
          ariaLabel: rest['aria-label'],
          trackingName,
          buttonText: typeof children === 'string' ? children : '',
        });
      }
      if (rest.onClick) {
        rest.onClick(args);
      }
    }

    return (
      <Tooltip title={disabledCopy ?? tooltip ?? ''}>
        <span>
          <LoadingButton
            {..._.omitBy(rest, ['onClick'])}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleClick}
            ref={ref}
            disabled={permissionDisabled || rest.disabled}
          >
            {children}
          </LoadingButton>
        </span>
      </Tooltip>
    );
  }
);

export default memo(SegmentLoadingButton);
