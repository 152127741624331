import _ from 'lodash';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { analyticsTrack } from 'utils/functions';
import { forwardRef, ForwardedRef, memo } from 'react';
import { PermissionScope } from 'types/apps';
import usePermissionStateAndCopyHook from 'hooks/usePermissionStateAndCopyHook';

// NOTE: Using this button component for a mailto: link does not seem to work
// Use the standard MUI Button component instead

export interface SegmentButtonProps extends ButtonProps {
  trackingName?: string;
  permissionScope?: PermissionScope;
  tooltip?: string;
}

const SegmentButton = forwardRef(
  (
    {
      trackingName,
      permissionScope,
      tooltip,
      children,
      color,
      ...rest
    }: SegmentButtonProps,
    ref: ForwardedRef<any>
  ) => {
    const { permissionDisabled, disabledCopy } = usePermissionStateAndCopyHook({
      permissionScope,
    });
    // TODO: we should get the actual click handler types in here properly
    function handleClick(...args: any) {
      if (trackingName) {
        const trackingString =
          trackingName || (typeof children === 'string' ? children : '');
        analyticsTrack(`${trackingString}`, {
          props: _.pick(rest, ['size', 'variant']),
          color,
          ariaLabel: rest['aria-label'],
          trackingName,
          buttonText: typeof children === 'string' ? children : '',
        });
      }
      if (rest.onClick) {
        rest.onClick(args);
      }
    }

    return (
      <Tooltip title={disabledCopy ?? tooltip ?? ''}>
        <span>
          <Button
            {..._.omitBy(rest, ['onClick'])}
            onMouseDown={(event) => event.stopPropagation()}
            onTouchStart={(event) => event.stopPropagation()}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleClick();
            }}
            ref={ref}
            disabled={permissionDisabled || rest.disabled}
            color={color}
          >
            {children}
          </Button>
        </span>
      </Tooltip>
    );
  }
);

export default memo(SegmentButton);
